.adminLine{
    display: flex;
    border-radius: 1em;
    height:5em;
    min-width:50%;
    align-items: center;
    justify-content: space-around;
    border:1px solid black;
    background-color:cornsilk;
    padding: 1em;
    font-weight: 500;
}
.deleteAdmin{
    all:unset;
    padding: 1em;
    border:1px solid red;
    text-align: center;
    margin-left:auto;
    margin-right: 1em;
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 700;
}
.deleteAdmin::selection{
    background-color: none;
}
.deleteAdmin:hover{
    background-color: red;
    opacity: 0.5;
}
.pAdmin{
    border-radius: 0.5em;
    margin:0.5em;
    border:1px solid black;
    padding:0.5em;
}