.backgroundTest{
    height:100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerTest{
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height:60%;
    padding:2em;
    background-color: antiquewhite;
    border-radius: 1em;
}
.questionArea{
    position: relative;
    flex:0 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid pink;
}
.h1Question{
    margin-top:1em;
    color:black;
    font-weight: 700;
}
.answersArea{
    flex:1;
    width: 100%;
    display: grid;
    max-height:100%;
    grid-template-columns: repeat(2, 1fr);
}
.buttonAnswer {
    text-align: center;
    word-wrap: break-word;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:1em;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 2.5em;
    display: inline-block;
    border-radius: 2em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;
    border:1px solid black;
    height: 20vh;
    width: auto;
    overflow-x: hidden;
    overflow-y: scroll;
   }
   .answerText{
    color: blue;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-word;
   }
   .answerTextLong{
    color: blue;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    word-break: break-word;
   }
   .buttonAnswer:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   }
   
   .buttonAnswer:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   }
   
   .buttonAnswer::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
   }
   
   .buttonAnswer::after {
    background-color: #fff;
   }
   
   .buttonAnswer:hover::after {
    opacity: 0;
   }
.questionNumber{
    position: absolute;
    left:1em;
    top:0;
}
.correctQuestion{
    color:green;
    position: absolute;
    top:0;
    right:3em;
}
.wrongQuestion{
    color:red;
    position: absolute;
    top:0;
    right:1em;
}
@media only screen and (max-width: 600px) {
    .answersArea{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .containerTest{
        min-height: 60%;
        width:100%;
    }
    .buttonAnswer{
        font-size: 4vw;
        width: 100%;
        max-height:10px;
        overflow: hidden;
    }
    .backgroundTest{
        min-height: 100vh;
    }
  }