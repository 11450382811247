.divSet{
    width:15em;
    height:20em;
    border: 1px solid black;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: antiquewhite;
    margin:1em;
}
.divSetAdd{
    width:15em;
    height:20em;
    border: 1px solid black;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    background-color: antiquewhite;
    justify-content: center;
    align-items: center;
    margin:1em;
}
.imgSvg{
    max-width: 50%;
}
.backgroundSets{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: pink;

}
.butonSet{
    all:unset;
    border:1px solid black;
    text-align: center;
    border-radius: 0.3em;
    width:6em;
    background-color: purple;
    font-weight: 500;
    color:white;
    margin-top:1em;
    cursor: pointer;
    height:1.5em;
}
.inputSet{
    all:unset;
    background-color: white;
    border: 1px solid black;
    border-radius: 1em;
    padding: 0.5em;
    max-width: 80%;
}
.pendingDeleteDiv{
    position:fixed;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    width:35em;
    height:40em;
    border: 1px solid black;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: antiquewhite;
    margin:1em;
    text-align: center;
}