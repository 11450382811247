.quizCard {
    display: flex;
    align-items: center;
    width: 30rem;
    height: 20rem;
    border: 1px solid black;
    border-radius: 1em;
    background-color: white;
    font-size: x-large;
    justify-content: center;
    padding: 1em;
    overflow: scroll;
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
}
.butonEdit{
    all: unset;
    border: 1px solid black;
    text-align: center;
    border-radius: 0.3em;
    width: 6em;
    background-color: purple;
    font-weight: 500;
    display: inline-block;
    color: white;
    margin-bottom: 0.5em;
    cursor: pointer;
    height: 1.5em;
    display: inline-block;
}
.all-card{
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.p-numbering{
    font-size: 16px;
    position: absolute;
    top:1em;
    left:1.2em;
}
.cover-final{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height:100vh;
}
p {
    user-select: none;
    overflow-wrap: break-word;
    max-width: 100%;
}

.quizCard:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.prevButton {
    user-select: none;
    all: unset;
    border: 1px solid black;
    text-align: center;
    border-radius: 0.3em;
    width: 6em;
    background-color: purple;
    font-weight: 500;
    display: inline-block;
    margin-left: 1em;
    margin-right: auto;
    color: white;
    margin-top: 1em;
    cursor: pointer;
    height: 1.5em;
    display: inline-block;
}

.spread-apart {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.shuffleButton{
    all: unset;
    border: 1px solid black;
    text-align: center;
    border-radius: 0.3em;
    width: 6em;
    background-color: purple;
    font-weight: 500;
    display: inline-block;
    color: white;
    margin-top: 1em;
    cursor: pointer;
    height: 1.5em;
}
.nextButton {
    all: unset;
    border: 1px solid black;
    text-align: center;
    border-radius: 0.3em;
    width: 6em;
    background-color: purple;
    font-weight: 500;
    display: inline-block;
    margin-left: auto;
    margin-right: 1em;
    color: white;
    margin-top: 1em;
    cursor: pointer;
    height: 1.5em;
    display: inline-block;
}

.quizCard p {
    overflow-wrap: break-word;
}
