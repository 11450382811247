.containerResult{
    width:50%;
    background-color: antiquewhite;
    border-radius: 1em;
}
.backgroundResult{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titluResult{
    margin-top: 2em;
    text-align: center;
}
.answerBox{
    margin-left: 2em;
    font-size: x-large;
}
.correctAnswer{
    color:green;
}
.wrongAnswer{
    color:red;
}
.percentResult{
    text-align: center;
    margin-bottom:2em;
}
.buttonBack{
    all:unset;
    border:1px solid black;
    text-align: center;
    border-radius: 0.3em;
    width:6em;
    background-color: purple;
    font-weight: 500;
    display:block;
    margin: auto;
    color:white;
    margin-top:1em;
    cursor: pointer;
    height:1.5em;
    margin-bottom:1em;
}